// --------------------------------------------------------
//
// Layout
//
// --------------------------------------------------------
// 說明 (完整說明見) src/all/all.scss
//	* 此檔案包含：Layout樣式 Loader、Header、Footer

// -------------------------------------------------------
$size: 150px;
$codingcat-textyellow: #FFEF8A;

/* Loader */
.loader {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 1000;
    width: 100vw;
    min-width: rem-calc(320);
    height: 100vh;
    background-color: rgba(255, 255, 255, 0.85);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    &.loaded {
        opacity: 0;
        transition: 0.6s;
    }

    .container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        margin: rem-calc(50) auto 0 auto;
        width: 50%;
        transform: scale(0.4);

        @include breakpoint(large) {
            transform: scale(0.75);
        }
    }

    @keyframes rotate {
        0% {
            transform: rotateX(-37.5deg) rotateY(45deg);
        }

        50% {
            transform: rotateX(-37.5deg) rotateY(405deg);
        }

        100% {
            transform: rotateX(-37.5deg) rotateY(405deg);
        }
    }

    .cube,
    .cube * {
        position: absolute;
        width: $size + 1;
        height: $size + 1;
    }

    .sides {
        animation: rotate 3s ease infinite;
        animation-delay: .8s;
        transform-style: preserve-3d;
        transform: rotateX(-37.5deg) rotateY(45deg);
    }

    .cube .sides * {
        box-sizing: border-box;
        background-color: rgba(255, 239, 138, 0.3);
        border: $size / 40 solid $codingcat-textyellow;
    }

    @mixin side($name, $rotate, $i) {
        .#{$name} {
            animation: #{$name}-animation 3s ease infinite;
            animation-delay: $i * 100ms;
            transform: #{$rotate} translateZ($size);
            animation-fill-mode: forwards;
            transform-origin: 50% 50%;
        }


        @keyframes #{$name}-animation {
            0% {
                opacity: 1;
                transform: #{$rotate} translateZ($size)
            }

            20% {
                opacity: 1;
                transform: #{$rotate} translateZ($size / 2)
            }

            70% {
                opacity: 1;
                transform: #{$rotate} translateZ($size / 2)
            }

            90% {
                opacity: 1;
                transform: #{$rotate} translateZ($size)
            }

            100% {
                opacity: 1;
                transform: #{$rotate} translateZ($size)
            }
        }
    }

    .cube .sides {
        @include side("top", rotateX(90deg), 0);
        @include side("bottom", rotateX(-90deg), 0);
        @include side("front", rotateY(0deg), 1);
        @include side("back", rotateY(-180deg), 1);
        @include side("left", rotateY(-90deg), 1);
        @include side("right", rotateY(90deg), 1);
    }

    @mixin position-center($text-align: center) {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        text-align: $text-align;
    }

    .loading {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #000;
        z-index: 9999;
    }

    .loading-text {
        // @include position-center;
        width: 100%;
        height: 100px;
        line-height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: rem-calc(20);

        @include breakpoint(large) {
            margin-top: rem-calc(70);
        }

        span {
            display: inline-block;
            padding: rem-calc(0 3);
            color: #2D3142;
            font-family: 'Quattrocento Sans', sans-serif;

            @include breakpoint(large) {
                padding: rem-calc(0 5);
            }

            &.l {
                animation: blur-text 0.8s 0.2s infinite linear alternate;
            }

            &.o {
                animation: blur-text 0.8s 0.4s infinite linear alternate;
            }

            &.a {
                animation: blur-text 0.8s 0.6s infinite linear alternate;
            }

            &.d {
                animation: blur-text 0.8s 0.8s infinite linear alternate;
            }

            &.i {
                animation: blur-text 0.8s 1s infinite linear alternate;
            }

            &.n {
                animation: blur-text 0.8s 1.2s infinite linear alternate;
            }

            &.g {
                animation: blur-text 0.8s 1.4s infinite linear alternate;
            }
        }
    }
}

@keyframes blur-text {
    0% {
        filter: blur(0px);
    }

    100% {
        filter: blur(1px);
    }
}

// -------------------------------------------------------

/* Header */
.hd {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;

    @include breakpoint(small down) {
        background-color: rgba(255, 255, 255, 0.5);
    }

    &.scrolling {
        background-color: rgba(255, 255, 255, 0.8);
        transition: 0.4s;

        .cont-ctr {
            padding: rem-calc(10 0);
            transition: 0.4s;
        }

        .hd-logo {
            max-width: rem-calc(100);
            background-image: url('../images/logo_color.png');
            background-size: cover;
            background-repeat: no-repeat;
            transition: 0.4s;

            img {
                opacity: 0;
                transition: 0.4s;
            }
        }

        .hd-menu {
            @include breakpoint(xlarge) {
                .hd-menu_text {
                    font-size: rem-calc(14);
                    margin: rem-calc(0 10 0 0);
                }

                .hd-menu_bgr {
                    width: rem-calc(45);
                    height: rem-calc(29.75);

                    span {

                        &.bgr3,
                        &.bgr4 {
                            top: rem-calc(12.75);
                        }

                        &.bgr5,
                        &.bgr6 {
                            top: rem-calc(25.5);
                        }
                    }
                }

                &.open {
                    .hd-menu_bgr {
                        span {

                            &.bgr1,
                            &.bgr6 {
                                transform: rotate(45deg);
                            }

                            &.bgr2,
                            &.bgr5 {
                                transform: rotate(-45deg);
                            }

                            &.bgr1 {
                                left: rem-calc(5);
                                top: rem-calc(5);
                            }

                            &.bgr2 {
                                left: calc(50% - 5px);
                                top: rem-calc(5);
                            }

                            &.bgr3 {
                                left: -50%;
                                opacity: 0;
                            }

                            &.bgr4 {
                                left: 100%;
                                opacity: 0;
                            }

                            &.bgr5 {
                                left: rem-calc(5);
                                top: rem-calc(17);
                            }

                            &.bgr6 {
                                left: calc(50% - 5px);
                                top: rem-calc(17);
                            }
                        }
                    }

                    .hd-menu_list {
                        top: 0vh;
                        transition: 1s;

                        @include breakpoint(medium down) {
                            top: 0;
                            right: rem-calc(0);
                        }
                    }
                }
            }
        }


        // @include breakpoint(xlarge) {
        //     &:hover {
        //         .cont-ctr {
        //             padding: rem-calc(20 0);
        //             transition: 0.4s;
        //         }

        //         .hd-logo {
        //             max-width: rem-calc(150);
        //         }

        //         // .hd-menu {
        //         //     transform: scale(1) translateX(rem-calc(0));
        //         //     transition: 0.4s;
        //         // }
        //     }
        // }
    }

    .cont-ctr {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: rem-calc(20 0);
        transition: 0.4s;

        @include breakpoint(medium down) {
            padding: rem-calc(10 0);
        }
    }

    .hd-logo {
        position: relative;
        z-index: 9;
        transition: 0s 0.4s;

        @include breakpoint(xlarge only) {
            max-width: rem-calc(150);
        }

        @include breakpoint(large down) {
            max-width: rem-calc(120);
        }

        @include breakpoint(small down) {
            max-width: rem-calc(100);
            background-image: url('../images/logo_color.png');
            background-size: cover;
            background-repeat: no-repeat;
            transition: 0.4s;

            img {
                opacity: 0;
                transition: 0.4s;
            }
        }

        &.open {
            background-image: url('../images/logo_color.png');
            background-size: cover;
            background-repeat: no-repeat;
            transition: 0.4s;

            img {
                opacity: 0;
                transition: 0.4s;
            }
        }
    }

    .hd-menu {
        display: flex;
        align-items: center;
        transition: 0.4s;

        &_text {
            font-size: rem-calc(14);
            font-weight: 600;
            margin: rem-calc(0 10 0 0);
            position: relative;
            z-index: 9;

            @include breakpoint(xlarge) {
                font-size: rem-calc(20);
                margin: rem-calc(5 20 0 0);
            }
        }

        &_bgr {
            width: rem-calc(45);
            height: rem-calc(29.75);
            position: relative;
            transform: rotate(0deg);
            transition: .5s ease-in-out;
            cursor: pointer;
            z-index: 9;

            @include breakpoint(xlarge) {
                width: rem-calc(60);
                height: rem-calc(35);
            }

            @include breakpoint(medium down) {
                transform: scale(0.7) translateX(rem-calc(10));
            }

            span {
                display: block;
                position: absolute;
                height: rem-calc(3);
                width: 50%;
                background: $main-txt;
                opacity: 1;
                transform: rotate(0deg);
                transition: .25s ease-in-out;

                @include breakpoint(xlarge) {
                    height: rem-calc(5);
                }

                &:nth-child(even) {
                    left: 50%;
                }

                &:nth-child(odd) {
                    left: 0px;
                }

                &.bgr1,
                &.bgr2 {
                    top: rem-calc(0);
                }

                &.bgr3,
                &.bgr4 {
                    top: rem-calc(12.75);

                    @include breakpoint(xlarge) {
                        top: rem-calc(15);
                    }
                }

                &.bgr5,
                &.bgr6 {
                    top: rem-calc(25.5);

                    @include breakpoint(xlarge) {
                        top: rem-calc(30);
                    }
                }
            }
        }

        &_list {
            width: 100vw;
            height: calc(100vh + 70px);
            background-color: $white;
            position: fixed;
            top: -150vh;
            right: 0;
            transition: 0.4s 0.4s;
            z-index: 8;

            @include breakpoint(large) {
                display: flex;
                align-items: center;
                justify-content: center;

                &::before {
                    content: '';
                    width: 120vw;
                    height: 20vw;
                    background-color: $white;
                    position: absolute;
                    bottom: -10vw;
                    left: -10vw;
                    border-radius: 50%;
                    transition: 0.4s;
                }
            }

            @include breakpoint(medium down) {
                max-width: rem-calc(400);
                top: 0;
                right: rem-calc(-400);
                padding-top: rem-calc(70);
            }

            .pagebtn {
                width: 100%;
                height: auto;
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;

                @include breakpoint(xxlarge) {
                    width: rem-calc(312);
                    height: rem-calc(312);
                }

                @include breakpoint(xlarge) {
                    width: rem-calc(240);
                    height: rem-calc(240);
                }

                @include breakpoint(large only) {
                    width: rem-calc(190);
                    height: rem-calc(190);
                }

                @include breakpoint(medium down) {
                    margin-bottom: rem-calc(20);
                }

                &-en {
                    font-size: rem-calc(26);
                    color: $main-txt;
                    opacity: 0.2;
                    transition: 0.4s;
                    position: relative;
                    z-index: 5;

                    @include breakpoint(xxlarge) {
                        font-size: rem-calc(40);
                    }

                    @include breakpoint(xlarge only) {
                        font-size: rem-calc(32);
                    }

                    @include breakpoint(large only) {
                        font-size: rem-calc(26);
                    }

                    @include breakpoint(medium down) {
                        opacity: 0.8;
                    }
                }

                &-ch {
                    font-size: rem-calc(16);
                    color: $main-txt;
                    opacity: 0.2;
                    transition: 0.4s;
                    position: relative;
                    z-index: 5;

                    @include breakpoint(xxlarge) {
                        font-size: rem-calc(24);
                    }

                    @include breakpoint(xlarge only) {
                        font-size: rem-calc(20);
                    }

                    @include breakpoint(large only) {
                        font-size: rem-calc(16);
                    }

                    @include breakpoint(medium down) {
                        opacity: 0.6;
                    }
                }

                &-bg {
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: 1;

                    &_cubeline {
                        opacity: 0;
                        transition: 0.4s;
                    }

                    &_cubefull {
                        opacity: 0;
                        transition: 0.4s;
                    }

                    &_line {
                        stroke-dasharray: 1000;
                        stroke-dashoffset: 1000;
                        pointer-events: none;
                        transition: 0.4s;
                    }
                }

                @include breakpoint(large) {
                    &:hover {
                        .pagebtn-bg {
                            &_cubeline {
                                opacity: 1;
                                transition: 0.4s 0.4s;
                            }

                            &_cubefull {
                                opacity: 1;
                                transition: 0.4s 0.4s;
                            }

                            &_line {
                                stroke-dashoffset: 0;
                                transition: 2.5s;
                            }
                        }

                        .pagebtn-ch {
                            color: $white;
                            opacity: 1;
                            transition: 0.4s 0.4s;
                        }

                        .pagebtn-en {
                            color: $codingcat-textyellow;
                            opacity: 1;
                            transition: 0.4s 0.4s;
                        }
                    }
                }
            }
        }

        &.open {
            .hd-menu_bgr {
                span {

                    &.bgr1,
                    &.bgr6 {
                        transform: rotate(45deg);
                    }

                    &.bgr2,
                    &.bgr5 {
                        transform: rotate(-45deg);
                    }

                    &.bgr1 {
                        left: rem-calc(5);
                        top: rem-calc(5);

                        @include breakpoint(xlarge) {
                            top: rem-calc(7);
                        }
                    }

                    &.bgr2 {
                        left: calc(50% - 5px);
                        top: rem-calc(5);

                        @include breakpoint(xlarge) {
                            top: rem-calc(7);
                        }
                    }

                    &.bgr3 {
                        left: -50%;
                        opacity: 0;
                    }

                    &.bgr4 {
                        left: 100%;
                        opacity: 0;
                    }

                    &.bgr5 {
                        left: rem-calc(5);
                        top: rem-calc(17);

                        @include breakpoint(xlarge) {
                            top: rem-calc(29);
                        }
                    }

                    &.bgr6 {
                        left: calc(50% - 5px);
                        top: rem-calc(17);

                        @include breakpoint(xlarge) {
                            top: rem-calc(29);
                        }
                    }
                }
            }

            .hd-menu_list {
                top: 0vh;
                transition: 1s;

                @include breakpoint(medium down) {
                    top: 0;
                    right: rem-calc(0);
                }
            }
        }
    }
}

// --------------------------------------------------------

/* Footer */
.ft {
    width: 100%;
    background-color: $white;

    .cont-ctr {
        padding: rem-calc(20 0 0 0);
        justify-content: space-between;

        @include breakpoint(medium) {
            display: flex;
            flex-wrap: wrap;
        }

        @include breakpoint(xxlarge) {
            padding: rem-calc(40 0 30 0);
        }

        @include breakpoint(xlarge only) {
            padding: rem-calc(30 0 10 0);
        }

        @include breakpoint(large only) {
            padding: rem-calc(30 0 10 0);
        }

        @include breakpoint(small down) {
            width: 100%;
        }
    }

    .ft-left {
        width: 100%;

        @include breakpoint(medium) {
            display: flex;
            width: auto;
        }

        @include breakpoint(medium down) {
            margin: 0 auto;
        }

        &_text {
            margin-right: rem-calc(30);

            @include breakpoint(xlarge only) {
                margin-right: rem-calc(20);
            }

            @include breakpoint(small down) {
                padding: rem-calc(0 0 0 20);
            }
        }
    }

    .ft-right {

        @include breakpoint(medium only) {
            width: 100%;
            text-align: center;

            &_text {
                text-align: center;
                font-size: rem-calc(12);
            }
        }

        @include breakpoint(small down) {
            background-color: rgba(0, 0, 0, 0.05);
            padding: rem-calc(10 0 5 0);
            margin-top: rem-calc(20);

            &_text {
                text-align: center;
                font-size: rem-calc(12);
            }
        }

        a{
            position: absolute;
            right: 0;
            top: 0;
        }
    }

    p {
        color: $main-txt;
        line-height: 100%;

        @include breakpoint(xlarge down) {
            font-size: rem-calc(13);
        }
    }

    .iconcont {
        display: flex;
        justify-content: space-between;

        @include breakpoint(medium only) {
            margin-right: rem-calc(20);
        }

        @include breakpoint(small down) {
            max-width: rem-calc(140);
            padding: rem-calc(10 0 10 0);
            margin: 0 auto;
        }

        a {
            opacity: 0.4;
            transition: 0.4s;
            margin: rem-calc(0 20 0 0);

            @include breakpoint(large) {
                &:hover {
                    opacity: 1;
                    transition: 0.4s;
                }
            }

            @include breakpoint(xlarge only) {
                margin: rem-calc(0 10 0 0);
            }

            @include breakpoint(large only) {
                margin: rem-calc(0 5 0 0);
            }

            @include breakpoint(medium down) {
                margin: rem-calc(0 5 0 0);
            }

            .co-icon {
                color: $main-txt;
                font-size: rem-calc(26);

                &::before {
                    transform: translateY(rem-calc(-4));

                    @include breakpoint(xlarge only) {
                        transform: translateY(rem-calc(-6));
                    }

                    @include breakpoint(large only) {
                        transform: translateY(rem-calc(-6));
                    }
                }
            }
        }
    }
}

.gotop {
    width: rem-calc(60);
    height: rem-calc(60);
    border-radius: rem-calc(60);
    overflow: hidden;
    right: rem-calc(-50);
    bottom: rem-calc(75);
    position: fixed;
    z-index: 99;
    opacity: 0;
    transition: 0.4s;
    cursor: pointer;

    @include breakpoint(xlarge) {
        width: rem-calc(36);
        height: rem-calc(36);
        right: rem-calc(-60);
    }

    &.scrolling {
        right: rem-calc(20);
        opacity: 1;
        transition: 0.4s;

        @include breakpoint(large) {
            opacity: 0.8;

            &:hover {
                opacity: 1;
                transition: 0.4s;
            }
        }
    }
}


// --------------------------------------------------------
// /* BG */ --------------------------------[Start]
// --------------------------------------------------------
.bgcont{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;

    .cubebg {
        height: 100%;
        background-size: 100%;
        background-repeat: no-repeat;
        position: absolute;
        top: 0;

        &.left {
            width: 35%;
            left: 0;
            background-image: url('../images/admin/linebg_left.svg');
        }

        &.right {
            width: 45%;
            right: 0;
            background-image: url('../images/admin/linebg_right.svg');

            @include breakpoint(medium down) {
                width: 85%;
            }
        }

        .hoverbox {
            fill: #FFEF8A;
            fill-opacity: 0;
            transition: 0.4s;

            @include breakpoint(large) {
                &:hover {
                    fill-opacity: 0.6;
                    transition: 0.4s;
                }
            }
        }
    }
}
// --------------------------------------------------------
// /* BG */ --------------------------------[End]
// --------------------------------------------------------







// --------------------------------------------------------
// /* PrivacyPage */ --------------------------------[Start]
// --------------------------------------------------------
.pr-cont{
    width: 100vw;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    background-size: 100% 300%;
    animation: slide 8s linear infinite;
    background-image: linear-gradient(-60deg, #6f96d1 20%, #9cb7e0 70%, #f2f3ee 100%, #ffffff 100%);
    position: relative;
    .pricont{
        padding: rem-calc(100 0 200 0);
    }
    &_title{
        width: 100%;
        color: $white;
        font-size: rem-calc(30);
        margin: rem-calc(0 0 10 0);
        line-height: 100%;
        @include breakpoint(large) {
            font-size: 3.2vw;
            line-height: 150%;
        }
    }
    &_subtitle{
        width: 100%;
        color: $white;
        font-size: rem-calc(16);
        letter-spacing: rem-calc(5);
        margin: rem-calc(0 0 0 0);
        @include breakpoint(large) {
            font-size: 1.5vw;
            margin: rem-calc(0 0 40 0);
        }
    }
    &_texttitle{
        width: 100%;
        font-size: rem-calc(18);
        letter-spacing: rem-calc(1.5);
        font-weight: 600;
        margin: 0;
        @include breakpoint(large) {
            font-size: rem-calc(22);
        }
    }
    &_text{
        width: 100%;
        font-size: rem-calc(16);
        letter-spacing: rem-calc(1.5);
        @include breakpoint(large) {
            font-size: rem-calc(20);
        }
    }
}
// --------------------------------------------------------
// /* PrivacyPage */ --------------------------------[End]
// --------------------------------------------------------