// --------------------------------------------------------
//
// Font - 字型
//
// --------------------------------------------------------

/* 中文襯線字 */
// 從 HTML 載入 Google Font 設定
.font-serif {
	font-family: 'Noto Serif TC', 'Karla', serif;
}

.karla {
	font-family: 'Karla', sans-serif;
}

.la {
	font-family: 'La Belle Aurore', cursive;
}

.blod {
	font-weight: 600;
}
