// --------------------------------------------------------
//
// 專案共用樣式
//
// --------------------------------------------------------
// ★內頁 不需要 @include all.scss(layout)已經有
// 說明 (完整說明見) src/sass/all.scss
//	* 此檔案包含：內頁共用元件
//		- HTML Tag
//		- 表單(Form)相關元件
//		- 修改框架/套件
//		- keyframes
// --------------------------------------------------------

/* Tag */

* {
	box-sizing: content-box;
	outline: none;
}

html,
body {
	width: 100%;
	min-width: rem-calc(320);
	// height: 100%;
	overflow-x: hidden;
	overflow-y: auto;
	background-color: $codingcat-pagecolor;
	font-family: 'Noto Serif TC', serif;
	-webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}

img {
	pointer-events: none;
}

a {
	.co-icon {
		color: $primary-color;
	}
}

a,
button,
.button {
	&:focus {
		outline: none;
	}
}

button {
	cursor: pointer;
}

%content_w {
	width: rem-calc(1560);
	margin: 0 auto;
	position: relative;

	@include breakpoint (xxxlarge only) {
		width: rem-calc(1560);
	}

	@include breakpoint (xxlarge only) {
		width: rem-calc(1360);
	}

	@include breakpoint (xlarge only) {
		width: rem-calc(1080);
	}

	@include breakpoint (large only) {
		width: rem-calc(900);
	}

	@include breakpoint (medium down) {
		width: calc(100% - 40px);
	}
}

%content_w-small {
	width: 1180px;
	margin: 0 auto;
	position: relative;

	@include breakpoint (xxlarge only) {
		width: 1180px;
	}

	@include breakpoint (xlarge only) {
		width: 1080px;
	}

	@include breakpoint (large only) {
		width: 950px;
	}

	@include breakpoint (medium down) {
		width: calc(100% - 30px);
	}
}

.cont-ctr {

	&.medium {
		@extend %content_w;
	}

	&.small {
		@extend %content_w-small;
	}
}

// --------------------------------------------------------

/* 反選顏色 | Selection */
$selection-text-color: $white; // 反選的文字顏色
$selection-bg-color: darken($primary-color, 5%); // 反選的背景顏色

@mixin selection-color($text-color, $bg-color) {
	::selection {
		color: $text-color;
		background: $bg-color;

		// 當瀏覽器Tab非Focus狀態
		&:window-inactive {
			color: #323232;
			background: #c5c5c5;
		}
	}

	// Firefox版本: https://css-tricks.com/window-inactive-styling/#comment-92675
	// 但因為gulp-ruby-sass看不懂導致編譯錯誤(Invalid CSS)，所以無法使用
	// :-moz-window-inactive ::-moz-selection
	// 	color: #323232
	// 	background: #C5C5C5
}

// No Selection | 無法反選
// https://stackoverflow.com/a/4407335/11240898
@mixin user-select($val: none) {
	-webkit-touch-callout: $val; // iOS Safari
	-webkit-user-select: $val; // Safari
	-khtml-user-select: $val; // Konqueror HTML
	-moz-user-select: $val; // Firefox
	-ms-user-select: $val; // Internet Explorer/Edge
	user-select: $val; // Non-prefixed version, currently, supported by Chrome and Opera
}

// +selection-color(反選的文字顏色, 反選的背景顏色)
@include selection-color($selection-text-color, $selection-bg-color);

// 無法反選 | No Selection
.no-selection {
	@include user-select(none);
}

// --------------------------------------------------------

// Custom Scroll Bar
// Mixin 移動到　src\sass\layout\_variable_mixin_function.scss
// 需使用則開啟下列樣式
@include breakpoint-hover {
	@include custom-scroll-bar;

	.custom-scrollbar {
		@include custom-scroll-bar;
	}
}

// --------------------------------------------------------

// Image
.img-absolute {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.img-rel {
	position: relative;
}

.img-deco {
	position: absolute;
	top: 0;
	left: 0;
}

// --------------------------------------------------------
// 文字溢行/文字點點點
%clamp {
	--line-height: 1.5;

	position: relative;
	display: block;
	display: -webkit-box;
	overflow: hidden;
	max-width: 100%;
	line-height: var(--line-height);
	text-overflow: ellipsis;
	-webkit-box-orient: vertical;
}

.clamp {

	&,
	&-1 {
		@extend %clamp;

		display: block;
		max-width: 100%;
		white-space: nowrap;
	}

	@function limit-height-get($i, $line-height) {
		@return calc(1em * #{$line-height} * #{$i});
	}

	@for $i from 2 through 6 {
		&-#{$i} {
			@extend %clamp;

			-webkit-line-clamp: $i;
			max-height: limit-height-get($i, 1.6);
			max-height: limit-height-get($i, var(--line-height));
		}
	}
}

// --------------------------------------------------------
/* object-fit */
// Fix the dimensions of an image and scale
// Default size: 1:1
//
// How to change size
// $imgWidth: // img width
// $imgHeight: // img height
// .object-fit:before{padding-top: round($imgHeight / $imgWidth * 100%)}
// round: round the number rounding
%object-fit-standard {
	position: relative;
	display: block; // Fixed <picture> bug
	overflow: hidden;

	&::before {
		content: '';
		display: block;
		padding-top: 100%;
	}

	img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100% !important;
		height: 100%;
		object-position: center center;
		background-position: center center;
	}
}

%object-fit-cover {
	img {
		object-fit: cover;
		background-size: cover;
	}
}

%object-fit-contain {
	background-position: center center;
	background-size: contain;

	img {
		object-fit: contain;
		background-size: contain;
	}
}

.object-fit {
	@extend %object-fit-standard;
	@extend %object-fit-cover;

	// 沒有內層(before)
	// &--wrap {
	// 	@extend %object-fit-standard;
	// 	@extend %object-fit-cover;

	// 	&::before {
	// 		display: none;
	// 	}
	// }

	// 應對不同比例的照片
	&--contain {
		@extend %object-fit-standard;
		@extend %object-fit-contain;
	}
}

// --------------------------------------------------------

/* Section */
.main-wrapper {
	position: relative;
}

// 區塊內容
.sec {
	padding-top: rem-calc(40);
	padding-bottom: rem-calc(40);
}

// --------------------------------------------------------

// 定義 SVG 隱藏於頁面使用
.svg-def {
	position: absolute;
	top: -9999px;
	left: -9999px;
	overflow: hidden;
	width: 0;
	height: 0;
	opacity: 0;
	pointer-events: none;
	speak: never;
}

// --------------------------------------------------------

/* Keyframes */
.hide-for-small {
	@include breakpoint(small down) {
		display: none;
	}
}

.show-for-small {
	@include breakpoint(medium) {
		display: none;
	}
}

.ccd-btn {
	font-size: rem-calc(16);
	color: $codingcat-textyellow;
	background-color: $main-txt;
	padding: rem-calc(6 30);
	transition: 0.4s;

	@include breakpoint(xlarge) {
		padding: rem-calc(10 40);
	}

	@include breakpoint(large) {
		&:hover {
			color: $main-txt;
			background-color: $codingcat-textyellow;
			transition: 0.4s;
		}
	}

	@include breakpoint(large only) {
		padding: rem-calc(6 30);
	}

	&.ctr {
		display: table;
		margin: 0 auto;
	}
}
