// Foundation for Sites
// https://get.foundation
// Licensed under MIT Open Source

////
/// @group breakpoints
////

/// Patch to fix issue #12080
$-zf-size: null;

/// A list of named breakpoints. You can use these with the `breakpoint()` mixin to quickly create media queries.
/// @type Map
$breakpoints: (
  "small": 0,
  "medium": 640px,
  "large": 1024px,
  "xlarge": 1200px,
  "xxlarge": 1441px,
  "xxxlarge": 1600px,
  ) !default;

/// A list of named HiDPI breakpoints. You can use these with the `breakpoint()` mixin to quickly create media queries for resolutions.
/// Values must represent the device pixels / web pixels ration and be unitless or in DPPX.
/// @type Map
$breakpoints-hidpi: (
  "hidpi-1": 1,
  "hidpi-1-5": 1.5,
  "hidpi-2": 2,
  "retina": 2,
  "hidpi-3": 3) !default;

/// The largest named breakpoint in which to include print as a media type
/// @type Keyword
$print-breakpoint: large !default;

$-zf-zero-breakpoint: small !default;

$-zf-breakpoints-keys: map-to-list($breakpoints, 'keys'
);

@if nth(map-values($breakpoints), 1) !=0 {
  @error 'The first key in the $breakpoints map must have a value of "0".';
}

@else {
  $-zf-zero-breakpoint: nth(map-keys($breakpoints), 1);
}

/// All of the names in this list will be output as classes in your CSS, like `.small-12`, `.medium-6`, and so on. Each value in this list must also be in the `$breakpoints` map.
/// @type List
$breakpoint-classes: (
  small medium large) !default;

/// Generates a media query string matching the input value. Refer to the documentation for the `breakpoint()` mixin to see what the possible inputs are.
///
/// @param {Keyword|Number} $val [small] - Breakpoint name, or px, rem, or em value to process.
@function breakpoint($val: $-zf-zero-breakpoint) {
  // Web standard Pixels per inch. (1ddpx / $std-web-dpi) = 1dpi
  // See https://www.w3.org/TR/css-values-3/#absolute-lengths
  $std-web-dpi: 96;

  // Size or keyword
  $bp: nth($val, 1
);
// Value of the following breakpoint
$bp-next: null;
// Value for max-width media queries
$bp-min: null;
// Value for min-width media queries
$bp-max: null;
// Direction of media query (up, down, or only)
$dir: if(length($val) > 1, nth($val, 2), up);
// If named, name of the breakpoint
$name: null;
// If the breakpoint is a HiDPI breakpoint
$hidpi: false;

// Orientation media queries have a unique syntax
@if $bp =='landscape' or $bp =='portrait' {
  @return '(orientation: #{$bp})';
}

// If a breakpoint name is given, get its value from the $breakpoints/$breakpoints-hidpi map.
@if type-of($bp)=='string' {
  @if map-has-key($breakpoints, $bp) {
    $name: $bp;
    $bp: map-get($breakpoints, $name);
    $bp-next: -zf-map-next($breakpoints, $name);
  }

  @else if map-has-key($breakpoints-hidpi, $bp) {
    $name: $bp;
    $bp: map-get($breakpoints-hidpi, $name);
    $bp-next: -zf-map-next-number($breakpoints-hidpi, $bp);
    $hidpi: true;
  }

  @else {
    $bp: 0;
    @warn 'breakpoint(): "#{$val}" is not defined in your `$breakpoints` or `$breakpoints-hidpi` setting.';
  }
}

@if not $name and $dir =='only' {
  @warn 'breakpoint(): Only named media queries can have an `only` range.';
  @return null;
}

// Only 'only' and 'up' have a min limit.
@if $dir =='only' or $dir =='up' {
  $bp-min: if($hidpi, strip-unit($bp), -zf-bp-to-em($bp));
}

// Only 'only' and 'down' have a max limit.
@if $dir =='only' or $dir =='down' {

  // If the breakpoint is a value, use it as max limit.
  @if not $name {
    $bp-max: if($hidpi, strip-unit($bp), -zf-bp-to-em($bp));
  }

  // If the breakpoint is named, the max limit is the following breakpoint - 1px.
  @else if $bp-next {
    // Max value is 0.2px under the next breakpoint (0.02 / 16 = 0.00125).
    // Use a precision under 1px to support browser zoom, but not to low to avoid rounding.
    // See https://github.com/foundation/foundation-sites/issues/11313
    $bp-max: if($hidpi, $bp-next - divide(1, $std-web-dpi), -zf-bp-to-em($bp-next) - 0.00125);
  }
}

// Generate the media query string from min and max limits.
@if $hidpi {
  // Generate values in DPI instead of DPPX for an IE9-11/Opera mini compatibility.
  // See https://caniuse.com/#feat=css-media-resolution
  $bp-min-dpi: if($bp-min, $bp-min * $std-web-dpi * 1dpi, $bp-min);
  $bp-max-dpi: if($bp-max, $bp-max * $std-web-dpi * 1dpi, $bp-max);
  @return zf-str-join(-zf-bp-join($bp-min, $bp-max, '-webkit-min-device-pixel-ratio', '-webkit-max-device-pixel-ratio'),
    -zf-bp-join($bp-min-dpi, $bp-max-dpi, 'min-resolution', 'max-resolution'),
    ', ');
}

@else {
  @return -zf-bp-join($bp-min, $bp-max);
}
}

/// Wraps a media query around the content you put inside the mixin. This mixin accepts a number of values:
///  - If a string is passed, the mixin will look for it in the `$breakpoints` and `$breakpoints-hidpi` maps, and use a media query there.
///  - If a pixel value is passed, it will be converted to an em value using `$global-font-size` as the base.
///  - If a rem value is passed, the unit will be changed to em.
///  - If an em value is passed, the value will be used as-is.
///
/// If multiple values are passed, the mixin will generate a media query for each of them as described above.
/// Since the content is duplicated for each breakpoint, this mixin should only be used with properties that
/// change across breakpoints.
///
/// @param {Keyword|Number} $values... - Breakpoint name or px/rem/em value to process.
///
/// @output If the breakpoint is "0px and larger", outputs the content as-is. Otherwise, outputs the content wrapped in a media query.
@mixin breakpoint($values...) {
  @for $i from 1 through length($values) {
    $value: nth($values, $i);
    $str: breakpoint($value);
    $bp: index($-zf-breakpoints-keys, nth($value, 1));
    $pbp: index($-zf-breakpoints-keys, $print-breakpoint);
    // Direction of media query (up, down, or only)
    $dir: if(length($value) > 1, nth($value, 2), up);

    $old-zf-size: null;

    // Make breakpoint size available as a variable
    @if global-variable-exists(-zf-size) {
      $old-zf-size: $-zf-size;
    }

    $-zf-size: nth($value, 1) !global; // get the first value to account for `only` and `down` keywords

    // If $str is still an empty string, no media query is needed
    @if $str =='' {
      @content;
    }

    // Otherwise, wrap the content in a media query
    @else {

      // For named breakpoints less than or equal to $print-breakpoint, add print to the media types
      // generate print if the breakpoint affects the print-breakpoint (or smaller).
      // This means the current condition only needs to be extended so 'down' always generates print.
      @if $bp  !=null and ($bp <=$pbp or $dir ==down) {

        @media print,
        screen and #{$str} {
          @content;
        }
      }

      @else {
        @media screen and #{$str} {
          @content;
        }
      }
    }

    $-zf-size: $old-zf-size  !global;
  }
}

/// Converts the breakpoints map to a URL-encoded string, like this: `key1=value1&key2=value2`. The value is then dropped into the CSS for a special `<meta>` tag, which is read by the Foundation JavaScript. This is how we transfer values from Sass to JavaScript, so they can be defined in one place.
/// @access private
///
/// @param {Map} $map - Map to convert.
///
/// @returns {String} A string containing the map's contents.
@function -zf-bp-serialize($map) {
  $str: '';

  @each $key,
  $value in $map {
    $str: $str +$key +'='+-zf-bp-to-em($value)+'&';
  }

  $str: str-slice($str, 1, -2);

  @return $str;
}

/// Find the next key in a map.
/// @access private
///
/// @param {Map} $map - Map to traverse.
/// @param {Mixed} $key - Key to use as a starting point.
///
/// @returns {Mixed} The value for the key after `$key`, if `$key` was found. If `$key` was not found, or `$key` was the last value in the map, returns `null`.
@function -zf-map-next($map, $key) {

  // Store the keys of the map as a list
  $values: map-keys($map);

  $i: 0;

  // If the Key Exists, Get the index of the key within the map and add 1 to it for the next breakpoint in the map
  @if (map-has-key($map, $key)) {
    $i: index($values, $key)+1;
  }

  // If the key doesn't exist, or it's the last key in the map, return null
  @if ($i > length($map) or $i ==0) {
    @return null;
  }

  // Otherwise, return the value
  @else {
    @return map-get($map, nth($values, $i));
  }

}

/// Find the next number in a map.
/// @access private
///
/// @param {Map} $map - Map to traverse.
/// @param {Mixed} $number - Number to use as a starting point.
///
/// @returns {Mixed} The number following `$number`, if `$number` was found. If `$number` was not found, or `$number` was the biggest number in the map, returns `null`.
@function -zf-map-next-number($map, $number) {

  $next-number: null;

  @each $k,
  $v in $map {
    @if type-of($v)=='number' and $v >$number and ($next-number ==null or $v < $next-number) {
      $next-number: $v;
    }
  }

  @return $next-number;
}

/// Return a list of our named breakpoints less than $key. Useful for dealing with
/// responsive gutters for the grid.
/// @access private
///
/// @param {String} $key - Key to use as last breakpoint.
///
/// @returns {Array} The list of breakpoints up to and. If $key is auto, returns breakpoints above the zero
@function -zf-breakpoints-less-than($key) {
  $list: ();
  $found-key: false;

  @each $name in $-zf-breakpoints-keys {
    @if ($name ==$key) {
      $found-key: true;
    }

    @if not $found-key {
      $list: append($list, $name);
    }
  }

  @return $list;
}

/// Return a list of our named breakpoints less than $key. Useful for dealing with
/// responsive gutters for the grid.
/// @access private
///
/// @param {String} $breakpoint - a named or non-named breakpoint.
///
/// @returns {Array} The list of breakpoints up to and. If $key is auto, returns breakpoints above the zero
@function -zf-closest-named-breakpoint($breakpoint) {
  $last: $-zf-zero-breakpoint;
  $found: false;

  $value: unitless-calc($breakpoint, 1px);

  @each $key,
  $val in $breakpoints {
    @if not $found {
      @if unitless-calc($val)>$value {
        $found: true;
      }

      @else {
        $last: $key;
      }
    }
  }

  @return $last;
}

/// Get a value for a breakpoint from a responsive config map or single value.
/// - If the config is a single value, return it regardless of `$value`.
/// - If the config is a map and has the key `$value`, the exact breakpoint value is returned.
/// - If the config is a map and does *not* have the breakpoint, the value matching the next lowest breakpoint in the config map is returned.
/// @access private
///
/// @param {Number|Map} $map - Responsive config map or single value.
/// @param {Keyword} $value - Breakpoint name to use.
///
/// @return {Mixed} The corresponding breakpoint value.
@function -zf-get-bp-val($map, $value) {

  // If the given map is a single value, return it
  @if type-of($map)=='number' {
    @return $map;
  }


  // Check if the breakpoint name exists globally
  @if not map-has-key($breakpoints, $value) {
    @if type-of($value)=='number' {
      $value: -zf-closest-named-breakpoint($value);
    }

    @else {
      @return null;
    }
  }

  // Check if the breakpoint name exists in the local config map
  @else if map-has-key($map, $value) {
    // If it does, just return the value
    @return map-get($map, $value);
  }

  // Otherwise, find the next lowest breakpoint and return that value
  @else {
    $anchor: null;
    $found: false;

    @each $key,
    $val in $breakpoints {
      @if not $found {
        @if map-has-key($map, $key) {
          $anchor: $key;
        }

        @if $key ==$value {
          $found: true;
        }
      }
    }

    @return map-get($map, $anchor);
  }
}

/// Return the best breakpoint to use according to the calling context. It returns in order:
/// 1. the given `$value` argument if it is not null.
/// 2. the global breakpoint context `$-zf-size` if it is not null (like if called inside then `breakpoint()` mixin)
/// 3. the given `$default` argument.
/// @access private
///
/// @param {Keyword} $value [null] - Breakpoint to use in priority if non-null.
/// @param {Keyword} $default [null] - Breakpoint to use by default if no other value can be used.
///
/// @return {Keyword} The resolved breakpoint.
@function -zf-current-breakpoint($value: null, $default: null) {
  @if ($value  !=null) {
    @return $value;
  }

  @else if (variable-exists(-zf-size) and type-of($-zf-size) !='number') and $-zf-size  !=null {
    @return $-zf-size;
  }

  @else {
    @return $default;
  }
}

/// Return media query string from the given min and/or max limits.
/// If a limit is equal to `null` or `0`, it is ignored.
/// @access private
///
/// @param {Number} $min [0] - Min media query limit.
/// @param {Number} $max [0] - Max media query limit.
/// @param {String} $min-name ['min-width'] - Name of the min media query limit.
/// @param {String} $delimiter ['max-width'] - Name of the max media query limit.
///
/// @returns {String} Media Query string.
@function -zf-bp-join($min: 0,
  $max: 0,
  $min-name: 'min-width',
  $max-name: 'max-width'

) {
  @return zf-str-join(if($min and $min > 0, '(#{$min-name}: #{$min})', null),
    if($max and $max > 0, '(#{$max-name}: #{$max})', null),
    ' and ');
}

$small-up: '';
$small-only: '';

@if map-has-key($breakpoints, small) {
  $small-up: screen;
  $small-only: unquote('screen and #{breakpoint(small only)}');
}

$medium-up: '';
$medium-only: '';

@if map-has-key($breakpoints, medium) {
  $medium-up: unquote('screen and #{breakpoint(medium)}');
  $medium-only: unquote('screen and #{breakpoint(medium only)}');
}

$large-up: '';
$large-only: '';

@if map-has-key($breakpoints, large) {
  $large-up: unquote('screen and #{breakpoint(large)}');
  $large-only: unquote('screen and #{breakpoint(large only)}');
}

$xlarge-up: '';
$xlarge-only: '';

@if map-has-key($breakpoints, xlarge) {
  $xlarge-up: unquote('screen and #{breakpoint(xlarge)}');
  $xlarge-only: unquote('screen and #{breakpoint(xlarge only)}');
}

$xxlarge-up: '';
$xxlarge-only: '';

@if map-has-key($breakpoints, xxlarge) {
  $xxlarge-up: unquote('screen and #{breakpoint(xxlarge)}');
  $xxlarge-only: unquote('screen and #{breakpoint(xxlarge only)}');
}

$xxxlarge-up: '';

@if map-has-key($breakpoints, xxxlarge) {
  $xxxlarge-up: unquote('screen and #{breakpoint(xxxlarge)}');
}
